export enum ApiEndpoint {
  // LANDING PAGE
  LandingPage = "landing-page",
  LandingPageAll = "landing-page/all",
  LandingPagePublish = "landing-page/publish",
  LandingPageUnpublish = "landing-page/unpublish",
  LandingPageBlock = "landing-page/block",
  LandingPageBlockAll = "landing-page/block/all",
  LandingPageFileUpload = "landing-page/file",

  // CDN
  FileCdnDelete = "cdn/delete",

  // PARTNER-CAMPAIGN
  PreApprove = "partner/pre-approve-campaigns",

  // LinkedIn
  LinkedInGetAccessUrl = "linkedin/generate-url-to-get-access",
  LinkedInAskForAccess = "linkedin/ask-for-access",
  LinkedInGetOrgs = "linkedin/get-organizations",
  LinkedInSetOrg = "linkedin/set-organization",
  LinkedInDisconnect = "linkedin/disconnect-partner",
}
